<template lang="pug">
    .main-wrapper.resgate-creditos-list
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-3
                    Button.p-button-secondary.btn-back(v-if='etapa != 1' icon='jam jam-chevrons-left' @click='changeEtapa()')
                .p-col-9.ta-right
                    h1.text-header.text-secondary MedClub / <b>Caixa Geral</b>

        Dialog.dialogDetalhesResgate(:modal='true' header="Histórico do caixa operacional" :visible.sync='dialogCaixa')
            DataTable.datatable(:value="saldo_caixa" class="p-datatable-striped")
                Column(headerStyle='width: 8%;' field='dt_saldo_f' header='Data / Horário' bodyStyle='text-align:center;')
                Column(headerStyle='width: 3%;' bodyStyle='text-align: center; justify-content: space-evenly;' header='Ações')
                    template(#body='props' style='')
                        Button.p-button-raised.p-button-rounded(v-tooltip.top="'Aceitar'"
                            icon='pi pi-external-link' @click='verCaixaOperacional(props.data.cd_saldo_caixa)')

        Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576' mode="indeterminate")
            .p-grid.p-fluid.p-align-end

                .p-col-12.p-md-3
                    label.form-label Caixa:
                    CustomMultiSelect(v-if='etapa == 3' v-model='cd_caixa_list_3' :options='options.cd_caixas_3' display='chip'
                        dataKey='value' optionLabel='label' optionValue='value' placeholder='TODAS' @change='applyFilters()' )
                    CustomMultiSelect(v-else v-model='cd_caixa_list_1' :options='options.cd_caixas' display='chip'
                        dataKey='value' optionLabel='label' optionValue='value' placeholder='TODAS' @change='applyFilters()' )

                .p-col-12.p-md-3
                    label.form-label Salas:
                    CustomMultiSelect(disabled v-model='filters.cd_unidades_list' :options='options.cd_unidades' display='chip'
                        dataKey='value' optionLabel='label' optionValue='value' placeholder='TODAS' @change='applyFilters()' )

                .p-col-12.p-md-3
                    label.form-label Data:
                    .p-inputgroup
                        Calendar( v-model='filters.dt_inicial' dateFormat="dd/mm/yy" :locale="ptbr"
                            :manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()'  placeholder='dd/mm/aaaa')
                        Button.p-button-danger(type='button' icon='jam jam-rubber' v-tooltip.top="'Limpar'" @click='filters.dt_inicial = null; applyFilters()')

                .p-col-12.p-md-3(v-if='etapa == 1')
                    label.form-label Data Final:
                    .p-inputgroup
                        Calendar(v-model='filters.dt_final' dateFormat="dd/mm/yy" :locale="ptbr"
                            :manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' placeholder='dd/mm/aaaa')
                        Button.p-button-danger(type='button' icon='jam jam-rubber' v-tooltip.top="'Limpar'" @click='filters.dt_final = null; applyFilters()')

                .p-col-12.p-md-9(v-if='etapa == 1')
                .p-col-12.p-md-3.ta-center
                    Button(label='Limpar filtros' icon='jam jam-rubber' @click='clearFilters()')
        .p-grid.p-fluid.p-align-end.p-justify-end
            .p-col-12.p-md-3.my-1
                ProgressBar(v-if='waiting' mode="indeterminate")
                SplitButton.p-button-secondary(v-else :label='totais[0].label' :model='totais')
            .p-col-12.p-md-1.my-1
            .p-col-12.p-md-1.my-1
            .p-col-12.p-md-1.my-1
            .p-col-12.p-md-2.my-1(style='justify-content: end; display: flex')
                Button.p-button-raised(v-tooltip.top="'Imprimir'" :disabled='true' icon='jam jam-printer')
            .p-col-12.p-md-2.my-1
                Button(label='Configurar Troco' @click='$router.push(`/troco/listar`)')
            .p-col-12.p-md-2.my-1
                Button.p-button-success(label='Transferências' :disabled='true' v-tooltip.top="'Inabilitado temporariamente'"  @click='$router.push(`/transferencias/listar/`)' )
            //- .p-col-12.p-md-2.my-1
            //-     Button.button-estorno(label='Solicitações de Estorno' :badge='qtdEstornos' @click='dialogEstorno = true')



        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else-if='! list.length')
            p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
        div(v-else)
            //- DataView.dataview.my-2(:value="list" layout="grid")
            //-     template(#grid="props")
            //-         .p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
            //-             Panel.panel-list.ta-center(:header="`Resgate #${props.data.id}`" style='position: relative')
            //-                 .ta-left
            //-                     p <b>Cliente: </b> {{ props.data.cd_pessoa_fisica.nm_pessoa_fisica }}
            //-                     p <b>Observação: </b> {{ props.data.ds_observacao }}
            //-                     p <b>Situação: </b>
            //-                         span(v-if="props.data.ie_situacao === 'SO'" class="highlight so") SOLICITADO
            //-                         span(v-if="props.data.ie_situacao === 'FI'" class="highlight fi") FINALIZADO
            //-                         span(v-if="props.data.ie_situacao === 'RE'" class="highlight re") RECUSADO
            //-                     p <b>Data: </b> {{ props.data.dt_criado }}
            //-                     p <b>Valor: </b> {{ formatPrice(props.data.nr_valor) }}

            //-                     .ta-right.mt-2
            //-                         Button.p-button-raised.p-button-rounded(v-tooltip.top="'Ver detalhes'"
            //-                             icon='jam jam-write' @click='openDialogResgate(props.data)')


            //- Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage")

            Panel.datatable(v-if='etapa == 1' header='Caixa Geral - Etapa 1 (Dias dos caixas supervisão)')
                DataTable.datatable(:value="list" dataKey="id" class="p-datatable-striped")
                    Column(headerStyle='width: 15%;' field='dt_saldo_f' header='Data' bodyStyle='text-align:center;')
                    Column(headerStyle='width: 15%;' field='inicial_f' header='Saldo Inicial' bodyStyle='text-align:right;')
                    Column(headerStyle='width: 15%;' field='entradas_f' header='Entradas' bodyStyle='text-align:right;')
                    Column(headerStyle='width: 15%;' field='saidas_f' header='Saídas' bodyStyle='text-align:right;')
                    Column(headerStyle='width: 15%;' field='saldo_dia_f' header='Saldo do Dia' bodyStyle='text-align:right;')
                    Column(headerStyle='width: 15%;' field='saldo_atual_f' header='Saldo Atual' bodyStyle='text-align:right;')
                    Column(headerStyle='width: 12%;' bodyStyle='text-align: center;' header='Ações' headerClass='box-hide' bodyClass='box-hide')
                        template(#body='props')
                            Button.p-button-raised.p-button-rounded(v-tooltip.top="'Ver detalhes'"
                                icon='jam jam-chevron-right' @click='etapa = 2; filters.dt_inicial = props.data.dt_saldo_f; applyFilters()')

                Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage")

            Panel.datatable(v-if='etapa == 2' header='Caixa Geral - Etapa 2 (Caixas supervisão)')
                DataTable.datatable(:value="list" dataKey="id" class="p-datatable-striped")
                    Column(headerStyle='width: 15%;' field='nm_caixa' header='Caixa' bodyStyle='text-align:center;')
                    Column(headerStyle='width: 15%;' field='inicial_f' header='Saldo Inicial' bodyStyle='text-align:right;')
                    Column(headerStyle='width: 15%;' field='entradas_f' header='Entradas' bodyStyle='text-align:right;')
                    Column(headerStyle='width: 15%;' field='saidas_f' header='Saídas' bodyStyle='text-align:right;')
                    Column(headerStyle='width: 15%;' field='saldo_dia_f' header='Saldo do Dia' bodyStyle='text-align:right;')
                    Column(headerStyle='width: 15%;' field='saldo_atual_f' header='Saldo Atual' bodyStyle='text-align:right;')
                    Column(headerStyle='width: 12%;' bodyStyle='text-align: center;' header='Ações' headerClass='box-hide' bodyClass='box-hide')
                        template(#body='props')
                            Button.p-button-raised.p-button-rounded(v-tooltip.top="'Ver detalhes'"
                                icon='jam jam-chevron-right' @click='etapa = 3; caixa_supervisao = props.data.nm_caixa; applyFilters()')

                Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage")

            Panel.datatable(v-if='etapa == 3' header='Caixa Geral - Etapa 3 (Caixas operacionais)')
                DataTable.datatable(:value="list" dataKey="id" class="p-datatable-striped")
                    Column(headerStyle='width: 15%;' field='nm_caixa' header='Caixa' bodyStyle='text-align:center;')
                    Column(headerStyle='width: 15%;' field='nr_dinheiro_f' header='Dinheiro' bodyStyle='text-align:right;')
                    Column(headerStyle='width: 15%;' field='nr_pix_f' header='Pix/Transferência' bodyStyle='text-align:right;')
                    Column(headerStyle='width: 15%;' field='nr_credito_f' header='Crédito' bodyStyle='text-align:right;')
                    Column(headerStyle='width: 15%;' field='nr_debito_f' header='Débito' bodyStyle='text-align:right;')
                    //Column(headerStyle='width: 15%;' field='nr_medcred_f' header='Crédito Medclub' bodyStyle='text-align:right;')
                    Column(headerStyle='width: 15%;' field='nr_total_f' header='Total do dia' bodyStyle='text-align:right;')
                    Column(headerStyle='width: 12%;' bodyStyle='text-align: center;' header='Ações' headerClass='box-hide' bodyClass='box-hide')
                        template(#body='props')
                            Button.p-button-raised.p-button-rounded(v-tooltip.top="'Ver detalhes'"
                                icon='pi pi-external-link' @click='openDialogCaixa(props.data.saldo_caixa)' )

                Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage")



</template>

<style lang="scss">
    .resgate-creditos-list {
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            .cell {
                padding: 16px 0;
                text-align: center;
                padding: 16px 0;
                &.ex  {  background-color: #e4f8e1; }
                &.a  { background-color: #faf3dd; }
                &.l  { background-color: #e1f0f8; }
                &.r  { background-color: #f1e1f8; }
            }
            .p-progress-spinner {
                max-width: 23px;
                height: auto;
            }
        }
        .dialog-confirm {
            width: 300px;
            .p-dialog-content {
                padding-bottom: 6px;
            }
            .p-button.p-button-text {
                background-color: transparent;
                color: #2196F3;
                border-color: transparent;

                &:hover {
                    border-color: #2196F3;
                }
            }
        }
        .dialogDetalhesResgate {
            width: 98%;
            max-width: 500px;
        }

        .dialogEstornoDetalhes {
            width: 100%;
            max-width: 1200px;
        }
        .highlight {
            background-color: #c9daf8;
            display: inline;
            font-weight: 700;

            &.fi { background-color: #c5efcb; }
            &.re { background-color: #f8d6d9; }
            &.so { background-color: #fff2cc; }
        }
        .text-small {
            .p-inputtext { font-size: 12px; }
            .form-label { font-size: 12px; }
        }
        .fieldset {
            border: 1px solid #c8c8c8;
        }
        .button-estorno {
            background-color: #9747FF;
            border-color: #9747FF;

        }
        .button-estorno :hover {
            background-color: #B884FC;
            border-color: #B884FC;
        }
        .button-transacoes {
            background-color: white;
            border-color: #CACACA;
            color: black;

        }
        .button-transacoes :hover {
            background-color: white;
            border-color: #CACACA;
            color:#CACACA;
        }
        .text-title {
            color: #657786;
            font-size: 14px;
            font-weight: 700;
        }
        .p-tooltip {
            max-width: none;
        }
        .waiting-small {
            max-width: 29px;
            height: auto;
        }
        .form-group--error .p-inputtextarea {
            border-color: #db5540;
        }
        .p-input-icon-left, .p-input-icon-right {
            position: relative;
            display: inline-block;
            width: 100%;
        }
        .p-input-icon-left>i, .p-input-icon-right>i {
            position: absolute;
            top: 50%;
            margin-top: -.5rem;
            cursor: pointer;
        }
        .p-input-icon-left > i:first-of-type {
            left: 0.5rem;
            color: #6c757d;
        }
        .p-input-icon-right > i:last-of-type {
            right: 0.5rem;
            color: #6c757d;
        }
        .p-input-icon-left > .p-inputtext {
            padding-left: 2rem;
        }
        .p-input-icon-right > .p-inputtext {
            padding-right: 2rem;
        }
        .p-splitbutton {
            border: 1px solid #a6a6a6;
            border-radius: 4px;
            .p-button {
                background-color: transparent;
                color: black;
                font-weight: bold;
            }
        }
    }
</style>

<script>
    import Button from 'primevue/button'
    import Calendar from 'primevue/calendar';
    import Column from 'primevue/column';
    import DataTable from 'primevue/datatable';
    import DataView from 'primevue/dataview';
    import Dialog from 'primevue/dialog';
    import Dropdown from 'primevue/dropdown';
    import InputMask from 'primevue/inputmask';
    import InputText from 'primevue/inputtext';
    import Paginator from 'primevue/paginator';
    import Panel from 'primevue/panel';
    import ProgressBar from 'primevue/progressbar';
    import Multiselect from 'primevue/multiselect';
    import ProgressSpinner from 'primevue/progressspinner';
    import Textarea from 'primevue/textarea';
    import Tooltip from 'primevue/tooltip';
    import SplitButton from 'primevue/splitbutton';
    import wsConfigs from "@/middleware/configs";
    import { Caixas, UnidadesMedclub } from "@/middleware";
    import moment from "moment";
    import { pCalendarLocale_ptbr } from '@/utils';
    import CustomMultiSelect from '../CustomComponents/CustomMultiSelect';
    import { formatPrice } from '../../utils';

    export default {
        components: { Button, Calendar, Column, DataTable, DataView, Dialog, Dropdown,
            InputText, InputMask, Paginator, Panel, ProgressBar, ProgressSpinner, Textarea, CustomMultiSelect, SplitButton, Multiselect },

        directives: { tooltip: Tooltip },
        created() {
            this.waiting = true

            UnidadesMedclub.findAll().then(response => {
                if(response.status === 200){
                    response.data.forEach(item => {
                        this.options.cd_unidades.push({
                            label: item.nm_unidade,
                            value: item.id
                        })
                    })
                }
            })
            this.applyFilters()
        },
        data() {
            return {
                ie_status: [
                        { value: 'A', text: 'Agendado', color: '#faf3dd' },
                        { value: 'P', text: 'Aguardando', color: '#FCD163' },
                    ],
                estornos: [
                    {
                        guia: 1058,
                        paciente: 'Manassés Silva dos Santos',
                        forma: 'Pix',
                        destino: '374.358.540-50',
                        valor: '50.00',
                        user: 'manassesss',
                        motivo: 'Cancelamento da compra',
                        data: moment('2023-07-25T16:06:27.623127-03:00').format('DD/MM/YYYY HH:mm'),
                        situacao: 'P',
                        situacao_color: '#187EF6'
                    },
                    {
                        guia: 4036,
                        paciente: 'Naum Celestino França',
                        forma: 'Transferência',
                        destino: 'Agência: 0001 Conta: 3231601-5',
                        valor: '150.00',
                        user: 'naum.celestino',
                        motivo: 'exame não realizado',
                        data: moment('2023-07-25T16:06:27.623127-03:00').format('DD/MM/YYYY HH:mm'),
                        situacao: 'P',
                        situacao_color: '#187EF6'
                    }
                ],
                sangria: [
                    {
                        caixa: 'Caixa 2',
                        posto: 'Medclub 1',
                        valor: '3000.00',
                        user: 'Manassés Silva dos Santos',
                        data_sol: moment('2023-07-25T16:06:27.623127-03:00').format('DD/MM/YYYY HH:mm'),
                        data_aceite: moment('2023-07-25T16:06:27.623127-03:00').format('DD/MM/YYYY HH:mm'),
                        situacao: 'P',
                        situacao_color: '#187EF6'
                    },
                    {
                        caixa: 'Caixa 7',
                        posto: 'Medclub 2',
                        valor: '10533.20',
                        user: 'Lucas Bandeira Miranda',
                        data_sol: moment('2023-07-25T16:06:27.623127-03:00').format('DD/MM/YYYY HH:mm'),
                        data_aceite: moment('2023-07-25T16:06:27.623127-03:00').format('DD/MM/YYYY HH:mm'),
                        situacao: 'P',
                        situacao_color: '#187EF6'
                    }
                ],
                submitted: false,
                waiting: false,
                windowInnerWidth: window.innerWidth,
                ptbr: pCalendarLocale_ptbr,
                waitingSalvarResgate: false,
                dialogResgate: false,
                dialogResgate_data: {
                    cd_pessoa_fisica: {}
                },
                dialogEstorno: false,
                dialogCaixa: false,
                saldo_caixa: [],
                waitingComprovante: false,
                comprovante: {},
                dialogConfirm: {
                    visible: false, msg: "", funcCallback: null, args: []
                },
                confirmOption: "RE",
                list: [],
                etapa: 1, // podem assumir 3 etapas, iniciando por 1. (refere-se as etapas das tabelas)
                totais: [],
                caixa_supervisao: '',
                unidades: [],
                paginator: {
                    page: this.$route.query.pg || 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                },
                order: {
                    field: 'dt_criado',
                    sortOrder: -1
                },
                cd_caixa_list_1: null,
                cd_caixa_list_3: null,
                filters: {
                    dt_inicial: null,
                    cd_caixa_list: null,
                    cd_unidades_list: null
                },
                options: {
                    cd_caixas: [],
                    cd_unidades: [],
                    cd_caixas_3: [],
                    cd_unidades_3: [],
                }
            }
        },
        methods: {
            isSupervisao() {
				let rotaAtual = this.$route.path.split('/')[1]
				return rotaAtual === "caixa-geral"
			},
            changeEtapa() {
                if(this.etapa === 2){
                    this.filters.dt_inicial = null
                }
                this.etapa = this.etapa - 1
                this.applyFilters()
            },
            formatPrice (val) {
                return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
            },
            formatFileName(name) {
                if (name.length < 30) return name
                return name.substr(0, 20) + "..." + name.substr(name.length -8)
            },
            onPage (ev) {
                this.paginator.page = ev.page + 1
                this.applyFilters()
            },
            openDialogCaixa(data) {
                this.dialogCaixa = true
                this.saldo_caixa = []
                data.forEach(item => {
                    this.saldo_caixa.push({
                        cd_saldo_caixa: item.cd_saldo_caixa,
                        dt_saldo: item.dt_saldo,
                        dt_saldo_f: moment(item.dt_saldo, 'YYYY-MM-DDTHH:mm:ssGMT-3').format('DD/MM/YYYY HH:mm')
                    })
                })
            },
            verCaixaOperacional(id){
				this.$router.push(`/fechamento-caixa/visualizar/${ id }/`)
			},

            clearFilters () {
                this.paginator.page = 1
                Object.keys(this.filters).forEach(k => this.filters[k] = null)
                this.applyFilters()
            },
            applyFilters () {
                let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
                params.order = `${ this.order.sortOrder === -1 ? '-' : '' }${ this.order.field }`
                if(this.etapa === 2){
                    params['ie_supervisao'] = true
                }
                if(this.etapa === 3) {
                    this.filters['cd_caixa_list'] = this.cd_caixa_list_3
                } else {
                    this.filters['cd_caixa_list'] = this.cd_caixa_list_1
                }
                if (this.$route.query.pg != this.paginator.page)
                    this.$router.replace( { query: { pg: this.paginator.page.toString() } } )

                Object.keys(this.filters).forEach((key) => {
                    if (this.filters[key]) {
                        if (key.substr(0, 3) === 'dt_') params[key] = moment(this.filters[key], 'DD/MM/YYYY').format('YYYY-MM-DD')
                        else params[key] = this.filters[key]
                    }

                })
                // console.log(params)

                this.totais = []
                this.getList(params)
            },
            getList (params) {
                this.waiting = true
                if(this.etapa === 3) {
                    Caixas.findAll({ie_tipo: 'O'}).then(response => {
                        if(response.status === 200){
                            this.options.cd_caixas_3 = []
                            response.data.forEach(item => {
                                this.options.cd_caixas_3.push({
                                    label: item.nm_caixa,
                                    value: item.id,
                                })
                                this.unidades[item.nm_caixa] = item.cd_unidades_medclub
                            })
                        }
                    })
                    Caixas.getCaixaGeralOperacional(params).then(response => {
                        if (response.status === 200) {
                            this.paginator.count = response.data.count
                            this.list = response.data.results
                            this.totais.push({label: "Total: " + formatPrice(response.data.total.nr_total)})
                            this.totais.push({label: "Saldo Dinheiro Total: " + formatPrice(response.data.total.nr_dinheiro)})
                            this.totais.push({label: "Saldo Pix/Transferência Total: " + formatPrice(response.data.total.nr_pix)})
                            this.totais.push({label: "Saldo Crédito Total: " + formatPrice(response.data.total.nr_credito)})
                            this.totais.push({label: "Saldo Débito Total: " + formatPrice(response.data.total.nr_debito)})
                            //this.totais.push({label: "Saldo Crédito Medclub Total: " + formatPrice(response.data.total.nr_medcred)})
                            this.list.forEach(item => {
                                item.nr_credito_f = this.formatPrice(item.nr_credito)
                                item.nr_debito_f = this.formatPrice(item.nr_debito)
                                item.nr_dinheiro_f = this.formatPrice(item.nr_dinheiro)
                                //item.nr_medcred_f = this.formatPrice(item.nr_medcred)
                                item.nr_pix_f = this.formatPrice(item.nr_pix)
                                item.nr_total_f = this.formatPrice(item.nr_total)

                            })
                        }
                        this.waiting = false
                    })
                } else {
                    Caixas.findAll({ie_tipo: 'S'}).then(response => {
                        if(response.status === 200){
                            this.options.cd_caixas = []
                            response.data.forEach(item => {
                                this.options.cd_caixas.push({
                                    label: item.nm_caixa,
                                    value: item.id,
                                })
                                this.unidades[item.nm_caixa] = item.cd_unidades_medclub
                            })
                        }
                    })
                    Caixas.getCaixaGeral(params).then(response => {
                        if (response.status === 200) {
                            this.paginator.count = response.data.count
                            this.list = response.data.results
                            this.totais.push({label: "Saldo Atual Total: " + formatPrice(response.data.total.saldo_atual)})
                            this.totais.push({label: "Saldo Inicial Total: " + formatPrice(response.data.total.inicial)})
                            this.totais.push({label: "Saldo Entrada Total: " + formatPrice(response.data.total.entradas)})
                            this.totais.push({label: "Saldo Saída Total: " + formatPrice(response.data.total.saidas)})
                            this.totais.push({label: "Saldo do Dia Total: " + formatPrice(response.data.total.saldo_dia)})
                            this.list.forEach(item => {
                                item.dt_saldo_f = item.dt_saldo ? moment(item.dt_saldo).format('DD/MM/YYYY') : ""
                                item.entradas_f = this.formatPrice(item.entradas)
                                item.inicial_f = this.formatPrice(item.inicial)
                                item.saidas_f = this.formatPrice(item.saidas)
                                item.saldo_atual_f = this.formatPrice(item.saldo_atual)
                                item.saldo_dia_f = this.formatPrice(item.saldo_dia)

                            })
                        }
                        this.waiting = false
                    })
                }

            },
            toastResponseDetail(detail) {
                if (!!detail && typeof detail == 'string') this.$toast.error(detail, { duration: 3000 })
                else if (typeof detail == 'object') detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
            },
            moment
        }
    }
</script>
